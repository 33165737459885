<template>
  <div class="page services" loaded>
    <section class="top-section">
      <div class="wrapper">
        <div class="container">
          <div class="content">
            <h1>Investor Readiness</h1>
            <h3>Unlock National Funding and Global Growth Opportunities with WestBIC</h3>
            <router-link class="btn white" to="/contact">Contact us</router-link>
          </div>
        </div>
      </div>
    </section>

    <!-- <section class="section text-section">
      <div class="wrapper">
        <div class="text-container">
          <div class="text-aside">WestBIC helps startups and scaling businesses in the West, North West and Midlands region access national funding programmes that enable international growth.</div>
          <div class="text-paragraph">
            <p>Looking for startup funding or investment readiness support?<br>Contact WestBIC today!</p>
            <router-link class="btn medium" to="/contact">Get in touch</router-link>
          </div>
        </div>
      </div>
    </section> -->

    <section class="section">
      <div class="wrapper">
        <div class="section-footer">
          <p>
            WestBIC helps startups and scaling businesses in the West, North West and Midlands region access national funding programmes that enable international growth.
            <br><br>
            Looking for startup funding or investment readiness support? Contact WestBIC today!
          </p>
          <router-link class="btn medium" to="/contact">Get in touch!</router-link>
        </div>
      </div>
    </section>

    <section class="section programmes">
      <div class="wrapper">
        <div class="section-title centered">
          <h3>Funded Programmes</h3>
          <h5>Your All-in-One Growth Boost</h5>
        </div>
        <div class="container">
          <div class="block">
            <img src="@/assets/images/wb_ic_grass.svg" alt="" class="block-icon">
            <div class="block-title">Pre-Seed Start Fund (PSSF)</div>
            <div class="block-description">
              Enterprise Ireland's Pre-Seed Start Fund (PSSF) provides funding to help early-stage start-ups develop their products or services.
              <br><br>
              WestBIC offers free advisory services to help with your application.
              <br><br>
              For more information and to apply for the Pre-Seed Start Fund (PSSF) visit the website
            </div>
            <a href="https://www.enterprise-ireland.com/en/supports/pre-seed-start-fund" class="btn medium" target="_blank" rel="noopener noreferrer">Visit website</a>
          </div>
          <div class="block">
            <img src="@/assets/images/wb_ic_folder.svg" alt="" class="block-icon">
            <div class="block-title">Prep4Seed</div>
            <div class="block-description">
              Prep4Seed is a 12-week intensive investor-readiness programme delivered nationally by the Irish BICs on behalf of Enterprise Ireland.
              <br><br>
              Selected start-ups participate in five masterclasses delivered by each of the BICs as well as tailored one-to-one meetings.
              <br><br>
              If you are interested in taking part, get in touch with WestBIC.
            </div>
            <a href="https://www.westbic.ie/news/westbic-company-gra-chocolates-takes-home-the-best-pitch-award-at-the-prep4seed-investor-pitch" class="btn medium" target="_blank" rel="noopener noreferrer">Visit website</a>
          </div>
          <div class="block">
            <img src="@/assets/images/wb_ic_star.svg" alt="" class="block-icon">
            <div class="block-title">Innovative High Potential Start-Ups (iHPSU)</div>
            <div class="block-description">
              Innovative High Potential Start-Ups (iHPSU) are innovative start-ups with high growth potential.
              <br><br>
              WestBIC helps businesses develop a strong investor proposal to attract investment.
              <br><br>
              To learn more about iHPSU funding, get in touch and talk to one of our funding experts visit the website
            </div>
            <a href="https://www.enterprise-ireland.com/en/supports/hpsu-feasibility-study-grant" class="btn medium" target="_blank" rel="noopener noreferrer">Visit website</a>
          </div>
        </div>
      </div>
    </section>

    <section class="section wide-image">
      <div class="wrapper">
        <div class="section-title centered">
          <h3>Funding Support & Investor Readiness<br> for Irish Start-ups</h3>
          <h5>We work with entrepreneurs on a one-to-one basis until they are at the investor ready stage, from ideation to commercialisation and provide support at all the stages in between.</h5>
        </div>
        <div class="section-image">
          <img src="@/assets/images/wb_bg_support-startups.jpg" alt="">
        </div>
      </div>
    </section>

    <section class="section image-text">
      <div class="wrapper">
        <div class="container">
          <div class="image-container">
            <img src="@/assets/images/wb_bg_invest.jpg" alt="" class="image">
            <div class="stat-item">
              <div class="stat-title">90%</div>
              <div class="stat-content">of WestBIC supported HPSUs Survive more than 5 years</div>
            </div>
            <div class="stat-item">
              <div class="stat-title">50%</div>
              <div class="stat-content">Int’l Avg. HPSUs Survive >5 yrs<br> (vs. WestBIC’s 90%)</div>
            </div>
          </div>
          <div class="content">
            <h3>To Become Investor Ready, you must have<br> a Compelling Offering.</h3>
            <h5>A compelling Innovative High Potential Start-Up (iHPSU) offering has the core objective of attracting investment.</h5>
            <p>
              Becoming Investor Ready requires an in-depth and practical analysis of the product/service technology, its market potential including international traction, and a team with the right skillset to drive and build the business.
              <br><br>
              WestBIC works with you to qualify and quantify these dynamic variables, providing a custom roadmap of supports to arrive at a robust business plan and a compelling investment pitch. Side by side with this we examine different financial options and connect entrepreneurs to the most appropriate sources of funding.
              <br><br>
              A quality iHPSU Investor Ready proposal will attract the attention of state and private funds.
            </p>
            <router-link class="btn medium" to="/contact">Get in touch</router-link>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Icon from "./Icon.vue";

export default {
  name: "Services",
  components: {
    Icon,
  },
  methods: {},
  mounted: function() {},
  beforeDestroy: function() {},
};
</script>

<style scoped>
.section {
  margin-bottom: 168px;
}

.section-title {
  position: relative;
  margin-bottom: 48px;
  padding: 0 96px;
}
.section-title.centered {
  text-align: center;
}
.section-title h3 {
  font-size: 4rem;
  line-height: 1.166666;
  color: var(--Metallic-Seaweed);
}
.section-title h5 {
  font-size: 2.25rem;
  font-weight: 600;
  line-height: 1.166666;
  margin-top: 16px;
}

.section-image {
  position: relative;
  padding-top: 33.42%;
  margin-bottom: 40px;
}
.section-image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 12px;
}

.section-footer {
  font-size: 1.5rem;
  font-weight: 500;
  text-align: center;
  padding: 0 96px;
}
.section-footer p::after {
  content: '';
  display: block;
  width: 587px;
  max-width: 100%;
  height: 3px;
  border-radius: 2px;
  background: linear-gradient(90deg, #0A7E8C 0%, #12E5FF 100%);
  margin: 32px auto 40px;
}

.top-section {
  margin-bottom: 96px;
}
.top-section .container {
  position: relative;
  display: flex;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-image: url(../assets/images/wb_bg_services-header.png);
  border-radius: 12px;
  overflow: hidden;
  padding-top: 53.4%;
}
.top-section .container .content {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: #fff;
  padding: 64px 96px;
  background: rgb(10,126,140);
  background: linear-gradient(36deg, rgba(10, 126, 140, 0.80) 13.82%, rgba(10, 126, 140, 0.50) 32.99%, rgba(10, 126, 140, 0.00) 64.59%);
}
.top-section .container .content .logo {
  display: block;
  width: 414px;
  max-width: 100%;
}
.top-section .container .content h1 {
  font-size: 8rem;
  line-height: 0.86;
  margin-bottom: 16px;
}
.top-section .container .content h3 {
  font-size: 4rem;
  margin-bottom: 32px;
  line-height: 1.1625;
  max-width: 980px;
}
.top-section .container .content p {
  font-size: 1.125rem;
}

.text-section .text-container {
  display: flex;
  flex-wrap: wrap;
  padding: 0 48px;
}
.text-section .text-container .text-aside {
  width: 42%;
  padding: 0 48px;
  font-size: 2.25rem;
  font-weight: 600;
  line-height: 1.16666666;
}
.text-section .text-container .text-paragraph {
  width: 58%;
  padding: 0 48px;
  font-size: 1.5rem;
  font-weight: 500;
}
.text-section .text-container .text-paragraph p::after {
  content: '';
  display: block;
  height: 3px;
  border-radius: 2px;
  background: linear-gradient(90deg, #0A7E8C 0%, #12E5FF 100%);
  margin: 40px 0;
}

.image-text .container {
  display: flex;
}
.image-text .image-container {
  display: flex;
  width: 580px;
  flex-shrink: 0;
  gap: 24px;
  flex-wrap: wrap;
}
.image-text .image-container .stat-item {
  width: calc(50% - 12px);
  padding: 48px 24px;
  text-align: center;
  background: url(../assets/images/wb_bg_stat-1.png) no-repeat center/cover;
  border-radius: 8px;
  font-size: 1.5rem;
  font-weight: 700;
  color: #fff;
}
.image-text .image-container .stat-item:nth-child(odd) {
  background-image: url(../assets/images/wb_bg_stat-2.png);
}
.image-text .image-container .stat-item .stat-title {
  font-size: 4.5rem;
  line-height: 1;
  margin-bottom: 8px;
}
.image-text .image-container img {
  width: 100%;
  object-fit: cover;
  border-radius: 12px;
}
.image-text .content {
  flex-grow: 1;
  padding: 0 96px;
}
.image-text h3 {
  font-size: 4rem;
  line-height: 1.166666;
  margin-bottom: 24px;
  color: var(--Metallic-Seaweed);
}
.image-text h5 {
  font-size: 2.25rem;
  font-weight: 600;
  line-height: 1.166666;
  color: var(--Dark-Blue);
  margin-bottom: 32px;
}
.image-text p {
  font-size: 1.5rem;
  font-weight: 500;
  margin-bottom: 40px;
}
.image-text p::after {
  content: '';
  display: block;
  height: 3px;
  border-radius: 2px;
  background: linear-gradient(90deg, #0A7E8C 0%, #12E5FF 100%);
  margin: 32px 0;
}

.programmes {
  background: url(../assets/images/wb_bg_programmes.png) no-repeat center/cover;
  padding: 80px 0;
}
.programmes .container {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  margin-top: 96px;
}
.programmes .container .block {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(33.333333% - 16px);
  background: #fff;
  border-radius: 12px;
  padding: 0 40px 48px;
  font-size: 1.25rem;
  line-height: 1.4;
  font-weight: 500;
  text-align: center;
  transition: all 0.2s ease-out;
}
.programmes .container .block:hover {
  box-shadow: 0 24px 32px rgba(63, 61, 86, 0.08);
}
.programmes .container .block .block-icon {
  width: 64px;
  margin-top: -32px;
  margin-bottom: 16px;
}
.programmes .container .block .block-title {
  position: relative;
  font-size: 2rem;
  font-weight: 600;
  width: 340px;
  max-width: 100%;
  min-height: 94px;
  padding-bottom: 24px;
  margin-bottom: 24px;
}
.programmes .container .block .block-title::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 3px;
  border-radius: 2px;
  max-width: 232px;
  margin: 0 auto;
  background: linear-gradient(90deg, #0A7E8C 0%, #12E5FF 100%);
}
.programmes .container .block .block-description {
  flex-grow: 1;
  margin-bottom: 40px;
}

.wide-image .section-title {
  padding: 0;
}
.wide-image .section-title h5 {
  padding: 0 96px;
}

@media screen and (max-width: 1280px) {
  .section {
    margin-bottom: 96px;
  }
  .section-title {
    padding: 0 64px;
  }
  .section-title h3 {
    font-size: 3rem;
  }
  .top-section .container .content {
    padding: 48px 64px;
  }
  .top-section .container .content .logo {
    width: 200px;
    left: 64px;
    top: 48px;
  }
  .top-section .container .content h1 {
    font-size: 6rem;
  }
  .top-section .container .content h3 {
    font-size: 3rem;
  }
  .text-section .text-container {
    padding: 0 32px;
  }
  .text-section .text-container .text-aside,
  .text-section .text-container .text-paragraph {
    padding: 0 32px;
  }
  .text-section .text-container .text-aside {
    font-size: 1.75rem;
  }
  .text-section .text-container .text-paragraph {
    font-size: 1.3125rem;
  }
  .image-text h3 {
    font-size: 3rem;
  }
  .image-text .image {
    width: 50%;
  }
  .image-text .content {
    padding: 64px;
  }
  .image-text h5 {
    font-size: 1.75rem;
  }
  .image-text h5 >>> br {
    display: none;
  }
  .image-text .image-container {
    width: 50%;
  }
  .programmes .container {
    justify-content: center;
    gap: 80px 24px;
  }
  .programmes .container .block {
    width: calc(50% - 12px);
  }
  .programmes .container .block .block-title {
    font-size: 1.75rem;
  }
}

@media screen and (max-width: 1024px) {
  .incubation .incubator img {
    height: 320px;
  }
  .incubation .incubator h5 {
    font-size: 1.75rem;
    margin-bottom: 16px;
  }
  .incubation .incubator p {
    font-size: 1.25rem;
  }
}

@media screen and (max-width: 880px) {
  .section {
    margin-bottom: 96px;
  }
  .section-title {
    padding: 0 48px;
  }
  .section-title h3 {
    font-size: 2.5rem;
  }
  .section-title h5 {
    font-size: 1.5rem;
  }
  .section-footer {
    padding: 0;
  }
  .top-section {
    margin-bottom: 48px;
  }
  .top-section .container {
    min-height: 400px;
  }
  .top-section .container .content {
    padding: 48px;
  }
  .top-section .container .content .logo {
    width: 170px;
    left: 48px;
    top: 48px;
  }
  .top-section .container .content h1 {
    font-size: 4.5rem;
  }
  .top-section .container .content h3 {
    font-size: 2rem;
  }
  .text-section .text-container {
    display: block;
    padding: 0 24px;
  }
  .text-section .text-container .text-aside,
  .text-section .text-container .text-paragraph {
    width: auto;
  }
  .text-section .text-container .text-aside {
    margin-bottom: 24px;
  }
  .image-text .container {
    flex-direction: column-reverse;
  }
  .image-text .image {
    width: 100%;
    max-height: 400px;
    object-fit: cover;
  }
  .image-text .content {
    padding: 0 0 48px;
  }
  .wide-image .section-title h5 {
    padding: 0;
  }
  .section-image {
    min-height: 240px;
  }
  .image-text .image-container {
    width: 100%;
  }
  .image-text .image-container img {
    order: 3;
  }
}

@media screen and (max-width: 740px) {
  .programmes .container .block {
    width: 100%;
  }
}

@media screen and (max-width: 580px) {
  .section-title {
    padding: 0;
  }
  .section-footer {
    font-size: 1.3125rem;
  }
  .top-section .container .content {
    padding: 32px;
  }
  .top-section .container .content h1 {
    font-size: 3rem;
  }
  .top-section .container .content h3 {
    font-size: 2rem;
  }
  .text-section .text-container {
    padding: 0;
  }
  .text-section .text-container .text-aside,
  .text-section .text-container .text-paragraph {
    padding: 0;
  }
  .text-section .text-container .text-aside {
    font-size: 1.3125rem;
  }
  .text-section .text-container .text-paragraph {
    font-size: 1.125rem;
  }
  .image-text h3 {
    font-size: 2rem;
  }
  .image-text h3 >>> br {
    display: none;
  }
  .image-text h5 {
    font-size: 1.5rem;
  }
  .image-text p {
    font-size: 1.3125rem;
  }
  .image-text .content {
    padding: 24px 0 0;
  }
}

@media screen and (max-width: 480px) {
  .top-section .container .content h3 {
    font-size: 1.5rem;
  }
  .programmes .container .block {
    width: 100%;
  }
}
</style>
